import React from 'react';
import Helmet from "react-helmet";

import { ThemeContext } from '../../context/theme-context';

import Layout from '../../components/layout';
import Footer from '../../components/footer';
import Title from '../../components/title';
import BannerImage from '../../components/bannerImage';
import VideoThumbnail from '../../components/videoThumbnail';
import ContactBlock from '../../components/contactBlock';
import NextCase from '../../components/nextCase';
import IntroBlock from '../../components/introBlock';
import BannerResult from '../../components/bannerResult';
import CarouselImage from '../../components/carouselImage';
import SimplePhrase from '../../components/simplePhrase';
import ChromaticPalette from '../../components/chromaticPalette';
import imgData from '../../data/trelo.json';

const CasesPage = ({ location }) => {

    return (
      <Layout bg="white" location={location}>
        <Helmet>
          <title>ATUO - Cases - Trelō</title>
        </Helmet>

        <div className="container mx-auto">
          <Title
            title="Trelō"
            titleColor="#EB5C2E"
            description="Inspiração, charme e elegância em uma marca que conquistou o interior"
            smallDescription="[sofisticada, elegante, exclusiva]"
          />

          <BannerImage src={`/trelo/trelo_content_one.jpg`} title="Trelō" />

          <IntroBlock
            logo={`/trelo/trelo_logo.svg`}
            alt="Trelô"
            description="Reposicionamento alinhado aos novos hábitos de consumo e crescimento da marca, que foi criada por três irmãs no interior de São Paulo, se expandiu e se tornou uma loja para toda a família."
            list={[
              "Estratégia & Inovacão",
              "Pesquisa & Inteligência",
              "Design de Experiência do Consumidor",
              "Cultura, Conteúdo & Social",
            ]}
          />

          <SimplePhrase paddingX={36}>
            Fazer a modernização da marca sem perder sua essência e sua
            história, construída com amor ao longo dos anos.
          </SimplePhrase>

          <div className="flex justify-center gap-x-16 md:px-24 items-center mt-24 mb-40 md:my-40 flex-col md:flex-row">
            <div className="md:w-1/2 md:px-16 text-2xl order-2 md:order-1 mt-24 md:mt-0">
              <p>
                O logo simboliza o elo das três irmãs fundadoras da marca,
                mantendo assim a história de sua criação. Os traços da letra E,
                usados de forma isolada e desconectados, representam o espaço
                para o universo masculino e a expansão da marca.
              </p>
            </div>

            <div className="md:w-1/2 order-1 md:order-2 text-center">
              <img
                src={`/trelo/trelo_stamp_1.svg`}
                alt="Trelô"
                className="inline"
              />
              <img
                src={`/trelo/trelo_stamp_2.svg`}
                alt="Trelô"
                className="inline"
              />
            </div>
          </div>

          <ChromaticPalette
            description={
              "A paleta cromática segue os arquétipos de Jung, tendo a presença do preto simbolizando elegância e sofisticação nos detalhes, e do laranja representando e dando vida à família como base da marca."
            }
          >
            <div className="py-24 md:py-16 flex flex-wrap gap-y-10 gap-x-9 md:gap-x-32 justify-evenly md:justify-center">
              <div
                className="cardColorBlock"
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-mirror="false"
                style={{ backgroundColor: "#ec5624" }}
              >
                #ec5624
              </div>
              <div
                className="cardColorBlock"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-mirror="false"
                style={{ backgroundColor: "#000000" }}
              >
                #000000
              </div>
            </div>
          </ChromaticPalette>

          <div className="flex flex-col md:flex-row justify-between mt-40">
            <div className="w-full md:w-3/5">
              <p className="text-2xl md:text-4xl md:leading-tight font-semibold mb-10">
                O slogan foi inspirado no depoimento de clientes, que ao longo
                do tempo se conectaram com a Trelô, fazendo com que a marca
                estivesse presente em grandes momentos de suas vidas.
              </p>

              <p className="text-2xl hidden md:block">
                O nome carrega a herança histórica e a identidade da marca,
                assim como sua conexão com os clientes e os elos familiares que
                trazem personalidade, legado, elegância e amor.
              </p>
            </div>

            <div className="text-center mt-10 md:mt-0">
              <img
                src={`/trelo/trelo_content_two.png`}
                alt="Trelô"
                className="w-3/5 inline"
              />
            </div>

            <p className="text-xl block md:hidden mt-10">
              O nome carrega a herança histórica e identidade da marca, assim
              como sua conexão com os clientes e os elos familiares que trazem
              personalidade, legado, elegância e amor.
            </p>
          </div>

          <div className="md:text-center md:mx-32 my-40 md:my-60">
            <p className="text-2xl md:text-4xl font-semibold">
              Uma marca que faz parte de grandes momentos de muitas famílias
            </p>
          </div>

          <VideoThumbnail
            src="/trelo/trelo_content_three.png"
            alt="Trelô"
            videoSrc="https://www.youtube.com/embed/-_NVanVDvcM"
          />
        </div>

        <div className="my-40">
          <div className="container my-40 grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-7">
            <p className="text-2xl md:text-4xl font-semibold leading-tight md:leading-tight">
              Os ambientes das lojas foram pensados e reprojetados para oferecer
              uma experiência de compra envolvente para os consumidores.
            </p>
            <p className="text-xl md:text-2xl md:mt-36">
              Todas as lojas da Trelô seguem um padrão que conecta a marca com o
              que há de mais moderno no mercado. <br />
              <br />
              Foi implementado um sistema de rotação de produtos, que
              possibilita que os lançamentos estejam sempre em relevância e
              destaque nos pontos quentes de circulação das lojas, criando um
              ambiente único e exclusivo a cada visita.
            </p>
          </div>

          <div className="mt-24 mt-16">
            <CarouselImage>
              {imgData.map(el => (
                <div className="item">
                  <img src={el.img} alt="Trelo" />
                </div>
              ))};
            </CarouselImage>
          </div>

          <p className="container text-right my-10 text-xl">
            [Um ambiente bem projetado que oferece bem estar para os clientes]
          </p>
        </div>

        <div className="container grid grid-cols-1 md:grid-cols-2 items-center gap-10 md:gap-20 my-20 md:my-40">
          <div>
            <img src={`/trelo/trelo_content_four.png`} alt="Trelô" />
          </div>
          <div className="md:pr-28">
            <p className="text-2xl md:text-4xl mb-10 font-semibold leading-tight md:leading-tight">
              O estilo Trelô se transformou em uma marca forte, traduzida como
              uma grife de conceito que estampa calças, camisas e muito mais.
            </p>

            <p className="text-xl">
              Vestir Trelô virou significado de bom gosto, tradição e atitude
              moderna, que se estende cada vez por lugares mais longes de onde a
              história começou.
            </p>
          </div>
        </div>

        <div className="container grid grid-cols-1 md:grid-cols-2 items-center gap-10 md:gap-20 my-40">
          <p className="text-2xl md:text-4xl  font-semibold leading-tight md:leading-tight md:pr-12">
            Trelô, a marca que vestiu gerações, agora com posicionamento forte,
            claro e renovado para a era digital.
          </p>
          <div>
            <img src={`/trelo/trelo_content_five.jpg`} alt="Trelô" />
          </div>
        </div>

        <BannerResult
          bg="#EB5C2E"
          list={[
            "Experiência de marca",
            "Marca com potencial de Grife",
            "Declaração de posicionamento claro",
            "Melhor experiência de compra",
            "40% de aumento nas vendas do mês de novembro. Black Friday Trelô",
            "Crescimento de + 14% em relação ao mesmo crescimento no primeiro ano de rebrand",
          ]}
        />

        <NextCase
          link="/cases/principal-papelaria"
          title="Principal Papelaria"
          description="Uma papelaria criativa e diferente, agora digital."
          image="/principal-next.png"
        />

        <ThemeContext.Consumer>
          {(cx) => (
            <ContactBlock
              description="Gostou dos nossos cases? Tem muito mais de onde veio! Clique no botão ao lado e entre em contato conosco. Vamos dar o primeiro passo para sua marca ir além."
              weather={cx?.cookies?.weather}
            />
          )}
        </ThemeContext.Consumer>

        <Footer />
      </Layout>
    );
};

export default CasesPage;
